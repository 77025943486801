import "bridgetown-quick-search/dist"
import "index.css";
import "screen.css";
// import "syntax-highlighting.css"

// Import all JavaScript & CSS files from src/_components
import components from "bridgetownComponents/**/*.{js,jsx,js.rb,css}";

console.info("Bridgetown is loaded!");


document.addEventListener("DOMContentLoaded", function () {
  console.log("DOM loaded");

  // simple show/hide
  // Show an element
  var show = function (elem) {
    elem.classList.add('is-visible');
  };

  // Hide an element
  var hide = function (elem) {
    elem.classList.remove('is-visible');
  };

  // Toggle element visibility
  var toggle = function (elem) {
    elem.classList.toggle('is-visible');
  };

  // Listen for click events
  document.addEventListener('click', function (event) {
    console.log("click event");
    // Make sure clicked element is our toggle
    if (!event.target.classList.contains('toggle')) return;

    // Prevent default link behavior
    event.preventDefault();

    // Get the content
    var content = document.querySelector(event.target.hash);
    if (!content) return;

    // Toggle the content
    toggle(content);

  }, false);

});
